<template>
	<v-dialog v-model="editDialog">
    	<template v-slot:activator="{ on, attrs }">
		  <v-btn
	      text 
	      accent 
	      v-bind="attrs"
	      v-on="on">

	        <v-icon>mdi-pencil</v-icon>
	        Edit Fintech
	      </v-btn>
	    </template>
		<v-card>
      <v-container>
        <v-row>
          <v-col>
            <v-card-title>Edit this Fintech</v-card-title>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <form @submit.prevent="onSaveChanges">
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="name"
                label="Name"
                id="name"
                v-model="editedName"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="location"
                label="City | Country"
                id="location"
                v-model="editedLocation"
                required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="url"
                label="Website of the Fintech"
                id="url"
                v-model="editedUrl"
                required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Give us a picture of this Fintech</h4>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-btn raised class="primary" @click="onPickFile">Upload Image</v-btn>
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="image/*"
                @change="onFilePicked">
            </v-col>
          </v-row>
          
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <img :src="editedImage" height="150">
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="description"
                label="Description"
                id="description"
                multi-line
                v-model="editedShort_des"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Choose the  Countries where it works</h4>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
                v-model="editedCountries"
                :items="countriesOp"
                item-text="name"
                item-value="alpha2"
                dense
                chips
                deletable-chips
                multiple
                label="Countries"
                solo>
                </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Choose the  Categories that apply to this Fintech</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
              v-model="editedCategories"
              :items="categoriesOp"
              item-text="name"
              dense
              chips
              deletable-chips
              multiple
              label="Categories"
              solo>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Choose the  Platforms  that apply to this Fintech</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
              v-model="editedPlatforms"
              :items="platformsOp"
              item-text="name"
              item-value="name"
              dense
              chips
              deletable-chips
              multiple
              label="Web, Android, Physical Branches,..."
              solo>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Optional: Choose the  Currencies this Fintech supports</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
              v-model="editedCurrencies"
              :items="currenciesOp"
              item-text="code"
              item-value="code"
              dense
              chips
              deletable-chips
              multiple
              label="EUR, USD, BTC,..."
              solo>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            
             
            
            <v-col xs12 sm6 offset-sm3>
               <v-btn
                  rounded
                  outlined
                  @click="editDialog = false">Close</v-btn>
              <v-btn
                rounded
                class="primary"
                :disabled="!formIsValid"
                type="submit"
                @click="onSaveChanges"
                >Confirm Changes
                </v-btn>
            </v-col>
          </v-row>
        </form>










       <!--  <v-layout row wrap>
          <v-flex xs12>
            <v-card-text>
              <v-text-field
                name="title"
                label="Title"
                id="title"
                v-model="editedTitle"
                required></v-text-field>
              <v-text-field
                name="description"
                label="Description"
                id="description"
                multi-line
                v-model="editedDescription"
                required></v-text-field>
            </v-card-text>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card-actions>
              <v-btn
                flat
                class="blue--text darken-1"
                @click="editDialog = false">Close</v-btn>
              <v-btn flat class="blue--text darken-1" @click="onSaveChanges">Save</v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout> -->
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
	import countriesData from '@/data/countries.json'
	import categoriesData from '@/data/subcategories.json'
	import platformsData from '@/data/platforms.json'
	import currenciesData from '@/data/currencies.json'

  export default {
    props: ['fintech'],
    data () {
      return {
        countriesOp: countriesData,
        categoriesOp: categoriesData,
        platformsOp: platformsData,
        currenciesOp: currenciesData,
        editDialog: false,
        editedName: this.fintech.name,
        editedShort_des: this.fintech.short_des,
        editedLocation: this.fintech.location,
        editedImage: this.fintech.image,
        editedCountries: this.fintech.countries,
        editedPlatforms: this.fintech.platforms,
        editedCurrencies: this.fintech.currencies,
        editedCategories: Array.from(this.fintech.categories),
        editedUrl: this.fintech.url

      }
    },
    computed: {
      formIsValid () {
        return this.name !== '' &&
          this.image !== '' &&
          this.short_des !== '' &&
          this.url !== ''
      }
    },
    methods: {
      onPickFile () {
        this.$refs.fileInput.click()
      },
      onFilePicked (event) {
        const files = event.target.files
        let filename = files[0].name
        if (filename.lastIndexOf('.') <= 0) {
          return alert('Please add a valid file!')
        }
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          this.image = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.rawImage= files[0]
      },
      onSaveChanges () {
        if (this.editedName.trim() === '' || this.editedShort_des.trim() === '') {
          return
        }
        this.editDialog = false
        this.$store.dispatch('updateFintechData', {
          name: this.fintech.name,
	        short_des: this.fintech.short_des,
	        location: this.fintech.location,
	        image: this.fintech.image,
	        countries: this.fintech.countries,
	        platforms: this.fintech.platforms,
	        currencies: this.fintech.currencies,
	        categories: this.fintech.categories,
	        url: this.fintech.url
        })
      }
    }
  }
</script>
	