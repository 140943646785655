const filterObj = {
    _fintechName: undefined,
    _category: undefined,
    _country: undefined,
    _filterCount: 0
}
Object.defineProperty(filterObj, "name", {
    enumerable: true,
    get(){
        return this._fintechName;
    },
    set(newVal) {
        if(newVal === ''){
            this._fintechName = undefined;
            this._filterCount--;
        } else {
            if(this._fintechName === undefined){
                this._filterCount++;
            }
            this._fintechName = newVal.toLowerCase();
        }
    }
});
Object.defineProperty(filterObj, "category", {
    enumerable: true,
    get(){
        return this._category;
    },
    set(newVal) {
        if(newVal === ''){
            this._category = undefined;
            this._filterCount--;
        } else {
            if(this._category === undefined){
                this._filterCount++;
            }
            this._category = newVal.toLowerCase();
        }
    }
});
Object.defineProperty(filterObj, "country", {
    enumerable: true,
    get(){
        return this._country;
    },
    set(newVal) {
        if(newVal === ''){
            this._country = undefined;
            this._filterCount--;
        } else {
            if(this._country === undefined){
                this._filterCount++;
            }
            this._country = newVal.toLowerCase();
        }
    }
});
Object.defineProperty(filterObj, "filterCount", {
    enumerable: true,
    get(){
        return this._filterCount;
    },
    set(newVal) {
        
        this._filterCount = newVal;
    }
});
export default filterObj;