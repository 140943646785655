<template>
  <v-app>
  
    <v-app-bar app color="primary" dark row wrap align-center>
      
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="sideNav = !sideNav"></v-app-bar-nav-icon>

    <v-container>
      <v-toolbar-title >
        <v-btn
        href='/'
        text
        >
          <v-img
          href='/'
          src='./assets/logo.png'
          max-width=30
          >
          </v-img>
          FintechList
       
      </v-btn>
      
      </v-toolbar-title>
    </v-container>
      
      <v-spacer></v-spacer>
     <v-container style="margin-top:0px">
        <!--class="hidden-xs-only"-->
      <v-toolbar-items class="hidden-sm-and-down" >
        <v-text-field 
          hide-details solo single-line 
          dense
          placeholder="Search Fintechs"
          append-icon="mdi-magnify"
          clearable
          
          light
          v-model="filterObj.name"
          @keyup="filterFintechs"
        ></v-text-field>
      </v-toolbar-items>
      </v-container>
     
      <!--
      <div>
          <b-form-select @input="sort()" v-model="search.filter" :options="options"/>
        </div>
      -->
    
      <v-container>
      <v-spacer></v-spacer>
    </v-container>
     
      <v-toolbar-items class="hidden-md-and-down" >
      <v-menu
                  v-for="category in categories"
                  :key="`${category.id}`"
                  offset-y
                  style="max-width: 600px"
                  open-on-hover
                  rounded
                >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          rounded
          v-bind="attrs"
          v-on="on"
          
        >
          <v-icon dark>
          {{category.icon}}
          </v-icon>
          {{ category.title }}
        </v-btn>

        </template>
        
        <v-list >
          <v-list-item-group
       
          active-class="deep-blue--text text--accent-4"
        >
        
          <v-list-item
            v-for="subcategory in category.subcategories"
            :key="`${subcategory.name}`"
            :to="'/categories/'+subcategory.name"
            
          >
            <v-list-item-content>
              <v-list-item-title >
                <v-icon>
                  {{subcategory.icon}}
                </v-icon>
                  {{subcategory.name}}
              </v-list-item-title>
            </v-list-item-content>
         </v-list-item>
          </v-list-item-group>
      
        </v-list>
        
        
      </v-menu>

       <v-divider inset
                       vertical
                       color="white"
            ></v-divider>
      <v-menu height="400px" class="scroll"
        
        offset-y
        style="max-width: 600px"
        open-on-hover
        rounded
      >
      <template v-slot:activator="{ on, attrs }">
      <v-btn
         text
          rounded
          v-bind="attrs"
          v-on="on" 
        >
        <v-icon dark>
          mdi-earth
          </v-icon>
        Countries
      </v-btn>
      </template>
      
      <v-list
      subheader
      two-line
      flat
      max-height="400px"
      class="overflow-y-auto"
    >

      <v-subheader>Countries</v-subheader>
     
      <v-list-item-group
        multiple

      >
        <v-list-item
        v-for="country in countries"
          :key="`${country.countrycode}`"
          :to="'/countries/'+country.name"
        >
          <template v-slot:default="{ active, }">
            <v-list-item-content>
              <v-list-item-title>{{country.name}}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>     
      </v-list-item-group>
    </v-list>
  
          
    </v-menu>
    
    </v-toolbar-items>
    
      <v-spacer></v-spacer>
    
    <v-menu
        v-if="userIsAuthenticated" 
        offset-y
        style="max-width: 600px"
        open-on-hover
        rounded
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        v-bind="attrs"
        v-on="on" 
        plain
        >
     <v-avatar 
        
        size="40"
        @click.stop="drawer = !drawer" 
        color="orange">
        <v-icon dark>
        mdi-account-circle
        </v-icon>
      </v-avatar>
      </v-btn>
    </template>
        <v-list
            nav
            dense
          >
            <v-list-item-group
           
              active-class="deep-blue--text text--accent-4"
            >
            <v-list-item
              v-for="UserOp in userOptions"
              :key="UserOp.title"
              link
              :to="UserOp.url"
            >
              <v-list-item-icon>
                <v-icon>{{ UserOp.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ UserOp.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
            @click="onLogout"
            >
              <v-list-item-icon>
                <v-icon >mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

              
            </v-list-item-group>
          </v-list>
    </v-menu>

    
        <v-toolbar-items v-if="!userIsAuthenticated">
          <v-btn  color="white"
              text
              rounded
               to='/login'>
            Login
          </v-btn>
          <v-btn  color="white"
              text
              rounded
             to='/signup'>
            Signup
          </v-btn>

    </v-toolbar-items>

     </v-app-bar>
    
      
      
      
     
   


    <v-navigation-drawer mdAndDown temporary v-model="sideNav"
    absolute
      width="290"
      left
      app
      >
      <v-card
        class="mx-auto"
        
      >
            <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Filter Fintechs
          </v-list-item-title>
          <v-text-field
          class="hidden-md-and-up"
          dense
          placeholder="Search Fintechs"
          append-icon="mdi-magnify"
          solo
          clearable
          full-width
          light
          v-model="filterObj.name"
          @keyup="filterFintechs"
        ></v-text-field>
          <v-list-item-subtitle>
            Filter by categories and countries
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-card>
      <v-list>
      
        
        <v-list-group
         v-for="category in categories"
        :key="`${category.id}`"
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>{{category.icon}}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{category.title}}</v-list-item-title>
            </v-list-item>
            
          </template>
          
          
          <v-list-item
            v-for="subcategory in category.subcategories"
            :key="`${subcategory.name}`"
            :to="'/categories/'+subcategory.name"
          >
              <v-list-item-title >
                <v-icon>
                  {{subcategory.icon}}
                </v-icon>
                  {{subcategory.name}}
              </v-list-item-title>
         </v-list-item>
     
          </v-list-group>
          <v-divider></v-divider>
          <v-list-group>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-flag</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Country</v-list-item-title>
          </v-list-item>
          </template>
          
          <v-list-item
            v-for="country in countries"
            :key="`${country.name}`"
            link
            :to="'/countries/'+country.name"
          >
           <!-- @change="filterFintechs" 
            v-model="filterObj.country" -->
              <v-list-item-title >
                  {{country.name}}
              </v-list-item-title>
         </v-list-item>
       </v-list-group>
        </v-list>
      
    </v-navigation-drawer>

    
  

       <!--
       <img
  src="https://queue.simpleanalyticscdn.com/noscript.gif?timezone=Europe%2FAmsterdam&unique=false"
  referrerpolicy="no-referrer-when-downgrade"
  alt=""
/>
-->

  <v-main>
      <router-view></router-view>
  </v-main>

    
    
 <!--
    <v-footer color="primary lighten-1" padless fixed>

      <v-layout primary  py-4 text-center white--text xs12 justify-center wrap>
        
        <v-btn
          v-for="link in links"
          :key="`${link.label}-footer-link`"
          color="white"
          text
          rounded
          class="my-2"
          :to="link.url"
        >
          {{ link.label }}
        </v-btn>
        
       
        
          {{ new Date().getFullYear() }} — <strong>FintechList</strong>
          
        

      </v-layout>
    </v-footer>
-->

  </v-app>

</template>

<script>
import categoriesData from './data/categories.json'
import countriesData from './data/countries.json'
import filterObj from "@/filters/filterObj";
export default {
  name: 'App',
  rounded: true,
  metaInfo: {
    title: 'List of Fintechs',
    titleTemplate: '%s | fintechlist',
    metaInfo: [
                { name: 'description', content:  'Your  🌍crowdsourced 📲 Fintech 📝List. Discover everything from the best Fintechs: where the fintechs work🇪🇺, what currencies 💵 they allow, what they do...'},
                { property: 'og:title', content: "Epiloge - Find the best Fintech for you"},
                { property: 'og:site_name', content: 'Epiloge'},
                { property: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/fintechlist-38539.appspot.com/o/FL_Banner.png?alt=media&token=495f4bdb-9abd-45b9-ad7e-86db756ad985'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
  },
  data() {
    return {
      filterObj,
      categories: categoriesData,
      countries: countriesData,
      countrySettings: {},
      showMenu: false,
      showMenu2: false,
      drawer: false,
      sideNav: false,
      links: [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Login',
          url: '/login',
        },
        {
          label: 'Signup',
          url: '/signup'
        }
      ],
      userOptions: [
        {
          title: 'My Account',
          icon: 'mdi-account',
          url: '/profile' //-> :username when database is created
        },
        {
          title: 'My Fintech List',
          icon: 'mdi-format-list-numbered',
          url: '/fintechs'
        },
        {
          title: 'Suggest New Fintech',
          icon: 'mdi-pencil',
          url: '/createfintech'
        }
      ],
      options: [
        { value: null, text: "Sort By" },
        { value: "b", text: "Likes" }
      ],
    }
  },
  computed: {
      menuItems () {
        let menuItems = [
          {icon: 'mdi-face', title: 'Sign up', link: '/signup'},
          {icon: 'mdi-lock-open', title: 'Sign in', link: '/login'}
        ]
        if (this.userIsAuthenticated) {
          menuItems = [
            {icon: 'mdi-format-list-numbered', title: 'View Meetups', link: '/fintechs'},
            {icon: 'mdi-bank-plus', title: 'Create a Fintech', link: '/createfintech'},
            {icon: 'person', title: 'Profile', link: '/profile'}
          ]
        }
        return menuItems
      },
      userIsAuthenticated () {
        return this.$store.getters.user !== null && this.$store.getters.user !== undefined
      },
      user () {
        return this.$store.getters.user
      }
    },
    methods: {
      onLogout () {
        this.$store.dispatch('logout')
      },
      filterFintechs() {
                this.$store.dispatch('filterFintechs', 
                this.filterObj)
      }
    }
}
</script>

<style>
.v-text-field{
      width: 400px;
}
.scroll {
   overflow-y: scroll
}
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.pick-class-label {
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

#icons-row i.now-ui-icons {
  font-size: 30px;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
  height: 100%;
}

#notifications {
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn {
  margin: 0 0px 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #FFFFFF;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

.section-navbars {
  padding-top: 0;
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
  pointer-events: none;
}

.section-tabs {
  background: #EEEEEE;
}

.section-pagination {
  padding-bottom: 0;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a+a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.components-page .title,
.tutorial-page .title {
  color: #FFFFFF;
}

.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}

.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-basic {
  padding-top: 0;
}

.section-images {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .section-navbars .navbar-collapse {
    display: none !important;
  }
}
</style>