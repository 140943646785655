<template>
  <v-dialog v-model="voteDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
      text 
      accent 
      v-bind="attrs"
      v-on="on">

        <v-icon>mdi-heart</v-icon>
        {{ userVoted ? 'Unvote' : 'Vote' }}
      </v-btn>
    </template>
    <v-card>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card-title v-if="userVoted">Remove your vote for this Fintech?</v-card-title>
            <v-card-title v-else>Vote this Fintech?</v-card-title>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card-text>You can always change your decision later</v-card-text>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card-actions>
              <v-btn
                class="red--text darken-1"
                text
                @click="voteDialog = false">Cancel</v-btn>
              <v-btn
                class="green--text darken-1"
                text
                @click="onAgree">Confirm</v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ['fintechId'],
    data () {
      return {
        voteDialog: false
      }
    },
    computed: {
      userVoted () {
        return this.$store.getters.user.votedFintechs.findIndex(fintechId => {
          return fintechId === this.fintechId
        }) >= 0
      }
    },
    methods: {
      onAgree () {
        if (this.userVoted) {
          this.$store.dispatch('unvoteUserFromFintech', this.fintechId)
        } else {
          this.$store.dispatch('voteUserForFintech', this.fintechId)
        }
        
      }
    }
  }
</script>