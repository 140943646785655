<template>
  <v-container>
    <h1 v-if="!fintechs">
      Oops, like like you haven't voted any fintechs yet
    </h1>
    <h1 v-else>
      My Fintech List (voted Fintechs)
    </h1>
    <v-container>
    <v-row>
    <v-col v-for="fintech in fintechs" :key="fintech.id"  wrap  class="mb-2">
      
        <v-card 
        max-width="374"
        rounded>
        <v-img
            :src="fintech.image"
            height="250"
            width="420"
          >
        </v-img>
          <v-card-title>
            {{fintech.name}}
          </v-card-title>
          
            <v-row>
              <v-col xs5 sm4 md3>
                <v-card-text>
                 <vote-dialog :fintechId="fintech.id">
                 </vote-dialog>
                </v-card-text>
                
                
              </v-col>
              <v-col xs7 sm8 md9>
                <v-card-title primary-title>
                
                    <h5 class="white--text mb-0">{{ fintech.title }}</h5>
                    
                </v-card-title>
                <v-card-text>
                  {{ fintech.short_des }}
                </v-card-text>
                <v-card-actions>
                  <v-btn  rounded outlined :to="'/' + fintech.name">
                    <v-icon left light>mdi-arrow-right</v-icon>
                    View
                  </v-btn>
                  <v-btn  rounded class="primary" :href="fintech.url">
                    <v-icon left >mdi-arrow-right</v-icon>
                    Go to {{fintech.name}}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-container>
</template>

<script>
  export default {
    computed: {
      fintechs () {
        return this.$store.getters.userFintechs
      }
    }
  }
</script>