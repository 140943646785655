<template>
  <v-container>
    <v-row>
      <v-col xs12 sm6 offset-sm3>
        <h1>Suggest a new Fintech</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-container>
      
        <form @submit.prevent="onCreateFintech">
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="name"
                label="Name"
                id="name"
                v-model="name"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="location"
                label="City | Country"
                id="location"
                v-model="location"
                required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="url"
                label="Website of the Fintech"
                id="url"
                v-model="url"
                required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Give us a picture of this Fintech</h4>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-btn raised class="primary" @click="onPickFile">Upload Image</v-btn>
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="image/*"
                @change="onFilePicked">
            </v-col>
          </v-row>
          
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <img :src="image" height="150">
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-text-field
                name="description"
                label="Description"
                id="description"
                multi-line
                v-model="short_des"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Choose the  Countries where it works</h4>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
                v-model="countries"
                :items="countriesOp"
                item-text="name"
                item-value="alpha2"
                dense
                chips
                deletable-chips
                multiple
                label="Countries"
                solo>
                </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Choose the  Categories that apply to this Fintech</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
              v-model="categories"
              :items="categoriesOp"
              item-text="name"
              dense
              chips
              deletable-chips
              multiple
              label="Categories"
              solo>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Choose the  Platforms  that apply to this Fintech</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
              v-model="platforms"
              :items="platformsOp"
              item-text="name"
              item-value="name"
              dense
              chips
              deletable-chips
              multiple
              label="Web, Android, Physical Branches,..."
              solo>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <h4>Optional: Choose the  Currencies this Fintech supports</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-autocomplete 
              v-model="currencies"
              :items="currenciesOp"
              item-text="code"
              item-value="code"
              dense
              chips
              deletable-chips
              multiple
              label="EUR, USD, BTC,..."
              solo>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs12 sm6 offset-sm3>
              <v-btn
                class="primary"
                :disabled="!formIsValid"
                type="submit">Create Fintech</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-container>
      
    </v-row>
  </v-container>
</template>

<script>
  import countriesData from '@/data/countries.json'
  import categoriesData from '@/data/subcategories.json'
  import platformsData from '@/data/platforms.json'
  import currenciesData from '@/data/currencies.json'
  export default {
    data () {
      return {

        countriesOp: countriesData,
        categoriesOp: categoriesData,
        platformsOp: platformsData,
        currenciesOp: currenciesData,
        name: '',
        location: '',
        image: '',
        rawImage: null,
        short_des: '',
        votes: 1,
        featured: false,
        categories: [],
        countries: [],
        platforms: [],
        currencies: [],
        url: ''

      }
    },
    computed: {
      formIsValid () {
        return this.name !== '' &&
          this.image !== '' &&
          this.short_des !== '' &&
          this.url !== ''
      }
    },
    methods: {
      onCreateFintech () {
        if (!this.formIsValid) {
          return
        }
        if (!this.image) {
          return
        }
        const fintechData = {
          name: this.name,
          location: this.location,
          image: this.image,
          short_des: this.short_des,
          votes: this.votes,
          featured: this.featured,
          categories: this.categories,
          countries: this.countries,
          platforms: this.platforms,
          currencies: this.currencies,
          url: this.url
          
        }
        this.$store.dispatch('createFintech', fintechData)
        this.$router.push('/fintechs')
      },
      onPickFile () {
        this.$refs.fileInput.click()
      },
      onFilePicked (event) {
        const files = event.target.files
        let filename = files[0].name
        if (filename.lastIndexOf('.') <= 0) {
          return alert('Please add a valid file!')
        }
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          this.image = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.rawImage= files[0]
      }
    }
  }
</script>