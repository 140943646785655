import Vue from 'vue'
import Vuex from 'vuex'
import { firebase } from "@firebase/app";
import "@firebase/database";
import "@firebase/auth";  // <- NEW
Vue.use(Vuex)

export const store = new Vuex.Store({
	state: {
		loadedFintechs: [],
		loadedFintech: {
			"id" : "",
		    "categories" : [],
		    "countries" : [ ],
		    "creatorId" : "",
		    "image" : "",
		    "location" : "",
		    "name" : "",
		    "short_des" : "",
		    "url" : "",
		    "votes" : 0
		  },
		user: null,
		loading: false,
		error: null,
		filterObj: {}
	},
	mutations: {
	    voteUserForFintech (state, payload) {
	      const id = payload.id
	      if (state.user.votedFintechs.findIndex(fintech => fintech.id === id) >= 0) {
	        return
	      }
	      //let _votes = state.loadedFintechs[fintech.id].votes
	      state.user.votedFintechs.push(id)
	      state.user.fbKeys[id] = payload.fbKey
	      let index = state.loadedFintechs.findIndex(fintech => fintech.id === payload.id)
	      state.loadedFintechs[index].votes += 1
	      //firebase.database().ref('fintechs').child(key).update({image: image})
	      //let key2Update = firebase.database().ref('fintechs').findIndex(fintech => fintech.id === payload)
	      firebase.database().ref('fintechs').child(payload.id).update({ votes: state.loadedFintechs[index].votes })
	      //firebase.database().ref('fintechs').findIndex(fintech => fintech.id === payload).update({ votes: state.loadedFintechs[index].votes })
	    },
	    unvoteUserFromFintech (state, payload) {

	      const votedFintechs = state.user.votedFintechs
	      let _votes = state.loadedFintechs[fintech.id].votes
	      votedFintechs.splice(votedFintechs.findIndex(fintech => fintech.id === payload.id), 1)
	      Reflect.deleteProperty(state.user.fbKeys, payload)
	      let index = state.loadedFintechs.findIndex(fintech => fintech.id === payload.id)
	      state.loadedFintechs[index].votes -= 1
	      //firebase.database().ref('fintechs').findIndex(fintech => fintech.id === payload).update({ votes: state.loadedFintechs[index].votes })
	      firebase.database().ref('fintechs').child(payload.id).update({ votes: state.loadedFintechs[index].votes })
	    },
		setUser (state, payload) {
			state.user = payload
		},
		createFintech (state, payload) {
			state.loadedFintechs.push(payload)
		},
		updateFintech (state, payload) {
	      const fintech = state.loadedFintechs.find(fintech => {
	        return fintech.id === payload.id
	      })
	      if (payload.name) {
	        fintech.name = payload.name
	      }
	      if (payload.short_des) {
	        fintech.short_des = payload.short_des
	      }
	      if (payload.categories) {
	        fintech.categories = payload.categories
	      }
	      if (payload.countries) {
	        fintech.countries = payload.countries
	      }
	      if (payload.location) {
	        fintech.location = payload.location
	      }
	      if (payload.platforms) {
	        fintech.platforms = payload.platforms
	      }
	      if (payload.currencies) {
	        fintech.currencies = payload.currencies
	      }
	      if (payload.url) {
	        fintech.url = payload.url
	      }
	    },
		setLoading (state, payload) {
			state.loading = payload
		},
		setError (state, payload) {
			state.error = payload
		},
		clearError (state) {
			state.error = null
		},
		setLoadedFintechs (state, payload) {
			state.loadedFintechs = payload
		},
		updateFilter(state, payload) {
        state.filterObj = payload;
    	},
	},
	actions: {
		voteUserForFintech ({commit, getters}, payload) {
	      commit('setLoading', true)
	      const user = getters.user
	      firebase.database().ref('/users/' + user.id).child('/votes/')
	        .push(payload)
	        .then(data => {
	          commit('setLoading', false)
	          commit('voteUserForFintech', {id: payload, fbKey: data.key})
	        })
	        .catch(error => {
	          //console.log(error)
	          commit('setLoading', false)
	        })
	    },
	    unvoteUserFromFintech ({commit, getters}, payload) {
	      commit('setLoading', true)
	      const user = getters.user
	      if (!user.fbKeys) {
	        return
	      }
	      const fbKey = user.fbKeys[payload]
	      firebase.database().ref('/users/' + user.id + '/votes/').child(fbKey)
	        .remove()
	        .then(() => {
	          commit('setLoading', false)
	          commit('unvoteUserFromFintech', payload)
	        })
	        .catch(error => {
	          //console.log(error)
	          commit('setLoading', false)
	        })
	    },
		loadFintechs ({commit}) {
			commit('setLoading', true)
			firebase.database().ref('fintechs').once('value')
			.then((data) => {
				const fintechs = []
				const obj = data.val()
				for (let key in obj) {
					fintechs.push({
						id: key,
						name: obj[key].name,
		        		location: obj[key].location,
		        		image: obj[key].image,
		        		short_des: obj[key].short_des,
		        		votes: obj[key].votes,
		        		categories: obj[key].categories,
		        		countries: obj[key].countries,
		        		url: obj[key].url,
		        		platforms: obj[key].platforms,
		        		currencies: obj[key].currencies,
		        		creatorId: obj[key].creatorId

					})
				}
				commit('setLoadedFintechs', fintechs)
				commit('setLoading', false)
			})
			.catch(
				(error) => {
					//console
					commit('setLoading', true)
				})
		},
		filterFintechs({commit}, filterObj) {
        commit('updateFilter', filterObj)
    	},
		createFintech({commit, getters}, payload){
			const fintech = {
				name: payload.name,
        		location: payload.location,
        		image: payload.image,
        		short_des: payload.short_des,
        		votes: payload.votes,
        		categories: payload.categories,
        		countries: payload.countries,
        		url: payload.url,
        		platforms: payload.platforms,
        		currencies: payload.currencies,
        		creatorId: getters.user.id
			}
			//reach out to firebase and store it
			let image
			let key
			firebase.database().ref('fintechs').push(fintech)
			.then((data) => {
				key = data.key
				return key
			})
			.then(key => {
				const filename = payload.image.name
				const ext = filename.slice(filename.lastIndexOf('.'))
				return firebase.storage().ref('fintechs/' + key + '.' + ext).put()
			})
			.then(fileData => {
				image = fileData.metadata.downloadURLs[0]
				return firebase.database().ref('fintechs').child(key).update({image: image})
			})
			.then (() => {
				commit('createFintech', {
					...fintech,
					image: image,
					id: key
				})
			})
			.catch((error) => {
				//console
			})
			
			
		},
		updateFintechData ({commit}, payload) {
	      commit('setLoading', true)
	      const updateObj = {}
	      if (payload.name) {
	        updateObj.name = payload.name
	      }
	      if (payload.short_des) {
	        updateObj.short_des = payload.short_des
	      }
	      if (payload.location) {
	        updateObj.location = payload.location
	      }
	      if (payload.image) {
	        updateObj.image = payload.image
	      }
	      if (payload.countries) {
	        updateObj.countries = payload.countries
	      }
	      if (payload.platforms) {
	        updateObj.platforms = payload.platforms
	      }
	      if (payload.currencies) {
	        updateObj.currencies = payload.currencies
	      }
	      if (payload.categories) {
	        updateObj.categories = payload.categories
	      }
	      if (payload.url) {
	        updateObj.url = payload.url
	      }
	      firebase.database().ref('fintechs').child(payload.id).update(updateObj)
	        .then(() => {
	          commit('setLoading', false)
	          commit('updateFintech', payload)
	        })
	        .catch(error => {
	          console.log(error)
	          commit('setLoading', false)
	        })
	    },
		signUserUp({commit},payload) {
			commit('setLoading', true)
			commit('clearError')
			firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
			.then(
				//trial

				//trial end
				user => {
					commit('setLoading', false)
					const newUser = {
						id: user.uid,
						votedFintechs: [],
						fbKeys: {},
						//create username and picture
						//create settings variables
						//upload all this, plus uid on the users table
					}
					commit('setUser', newUser)
				})
			.catch(
				error => {
					commit('setLoading', false)
					commit('setError', error)
					//console.log(error)
				})
			// const user = {
			// 	username: payload.name,
   //      		email: payload.location,
   //      		image: payload.image,
   //      		short_des: payload.short_des,
   //      		votes: payload.votes,
			// }
			// //reach out to firebase and store it
			// let image
			// let key
			// firebase.database().ref('users').push(user)
			// .then((data) => {
			// 	key = data.key
			// 	return key
			// })
			// .then(key => {
			// 	const filename = payload.image.name
			// 	const ext = filename.slice(filename.lastIndexOf('.'))
			// 	return firebase.storage().ref('users/' + key + '.' + ext).put()
			// })
			// .then(fileData => {
			// 	image = fileData.metadata.downloadURLs[0]
			// 	return firebase.database().ref('users').child(key).update({image: image})
			// })
			// .then (() => {
			// 	commit('createFintech', {
			// 		...fintech,
			// 		image: image,
			// 		id: key
			// 	})
			// })
			// .catch((error) => {
			// 	//console
			// })

		},
		signUserIn ({commit}, payload) {
	      commit('setLoading', true)
	      commit('clearError')
	      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
	        .then(
	          user => {
	            commit('setLoading', false)
	            const newUser = {
	              id: user.uid,
	              votedFintechs: [],
	              fbKeys: {}
	            }
	            commit('setUser', newUser)
	          }
	        )
	        .catch(
	          error => {
	            commit('setLoading', false)
	            commit('setError', error)
	            //console.log(error)
	          }
	        )
	    },
	    fetchUserData ({commit, getters}) {
	    	commit('setLoading', true)
	    	firebase.database().ref('/users/'+ getters.user.id +'/votes/').once('value')
	    	.then(data => {
	    	//should include rest of data
	    		const dataPairs = data.val()
	    		let votedFintechs = []
	    		let swappedPairs = {}
	          for (let key in dataPairs) {
	            votedFintechs.push(dataPairs[key])
	            swappedPairs[dataPairs[key]] = key
	          }
	          const updatedUser = {
	            id: getters.user.id,
	            votedFintechs: votedFintechs,
	            fbKeys: swappedPairs
	          }
	    		commit('setLoading', false)
	    		commit('setUser', updatedUser)
	    	})
	    	.catch(error => {
	    		commit('setLoading', false)
	    	})
	    },
	    autoSignIn ({commit}, payload) {
	    	commit('setUser', {
	    		id: payload.uid,
	    		votedFintechs: [],
	    		fbKeys: {}
	    	})
	    },
	    logout ({commit}) {
	    	firebase.auth().signOut()
	    	commit('setUser', null)
	    },
	    clearError ({commit}) {
	    	commit('clearError')
	    }
	},
	getters: {
		//fetchUserData
		//to fetch the contents of the table for the user
		userFintechs (state) {
			//not working yet
			//return state.loadedFintechs.find((user) =>{
			//			return fintech.name === fintechid
			//		})
						//return state.loadedFintechs.id === state.user.votedFintechs.id

				//store.user.votedFintechs
				//loadedFintechs
				var myarray = []
				for (let i = 0; i < state.user.votedFintechs.length; i++){
					var attribute = state.user.votedFintechs[i]
					// let objeto1 = state.loadedFintechs.filter(objeto=> {
					//      myarray.push( objeto.id.match(attribute))
					// })
					var myobjeto = state.loadedFintechs.find(match =>{return match.id.match(attribute)})
					if(myobjeto !== undefined){
					myarray.push(myobjeto)
					}
					
				}
				return myarray

			// return state.loadedFintechs.filter(fintech => {
   //                  for(let key in state.user.votedFintechs) {
   //                      if(key) {
   //                     if(key.toLowerCase() === state.loadedFintechs[key].id.toLowerCase())
   //                              return true
   //                      }
   //                  }
   //                  return false;
   //              })
			
		},
		loadedFintechs (state) {
			switch(state.filterObj.filterCount) {
            //no filters passed
            // not right yet, should filter and sort
            case 0:
            	return state.loadedFintechs.sort((fintechA, fintechB) => 
				(fintechB.votes > fintechA.votes) ? 1 : -1
				)
            case undefined:
            	return state.loadedFintechs.sort((fintechA, fintechB) => 
				(fintechB.votes > fintechA.votes) ? 1 : -1
				)
                //return state.loadedFintechs.sort((fintechA, fintechB) => {
				//return fintechA.votes > fintechB.votes
				//})
//only 1 filter passed. f1 OR f2. Should do (f1 OR f2) OR f3
            case 1:
                return state.loadedFintechs.filter(fintech => {
                    for(let key in state.filterObj) {
                        if(fintech[key]) {
                       if(fintech[key].toLowerCase().includes(state.filterObj[key]))
                                return true
                        }
                    }
                    return false;
                })
//2 filters passed. f1 AND f2. Should do (f1 AND f2) OR (f2 AND f3) OR (f1 AND f3)
            case 2:
                return state.loadedFintechs.filter(fintech => {
                    for(let key in state.filterObj) {
                        if(fintech[key]) {
                            if(!fintech[key].toLowerCase().includes(state.filterObj[key]))
                                return false;
                        }
                    }
                    return true;
                })
//3 filters passed, not right yet, copied from 2 filters, should do (f1 AND f2) AND f3
             case 3:
                return state.loadedFintechs.filter(fintech => {
                    for(let key in state.filterObj) {
                        if(fintech[key]) {
                            if(!fintech[key].toLowerCase().includes(state.filterObj[key]))
                                return false;
                        }
                    }
                    return true;
                })

        }
			
		},
		// For future Ads
		//featuredFintechs (state, getters) {
		//	return getters.loadedFintechs.slice(0,5)
		//},
		loadedFintech (state) {
			return (fintechId) => {
				return state.loadedFintechs.find((fintech) =>{
						return fintech.name === fintechId
					})
			}
		},
		user (state) {
			return state.user
		},
		loading (state) {
			return state.loading
		},
		error (state) {
			return state.error
		}
	}
})