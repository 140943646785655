import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import VueMeta from 'vue-meta';
import Fintechs from '@/views/Fintechs'
import CreateFintech from '@/views/CreateFintech'
import Profile from '@/views/Profile'
import AuthGuard from './auth-guard'

Vue.use(Router)
Vue.use(VueMeta)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Dashboard,
      title: 'fintechlist'
    },
    {
      path: '/fintechs',
      name: 'Fintechs',
      component: Fintechs,
      beforeEnter: AuthGuard
    },
    {
      path: '/createfintech',
      name: 'Add a Fintech',
      component: CreateFintech,
      beforeEnter: AuthGuard
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/Signup.vue')
    },
    {
        path: '/:fintechid',
        name: 'fintech',
        props: true,
        component: () =>
            import ('@/views/SingleFintech.vue')
    },
    {
        path: '/countries/:countryid',
        name: 'countryfintechs',
        props: true,
        component: () =>
            import ('@/views/Dashboard.vue')
    },
    {
        path: '/categories/:categoryid',
        name: 'categoryfintechs',
        props: true,
        component: () =>
            import ('@/views/Dashboard.vue')
    },
    {
        path: '/profile', //profile -> :username
        name: 'profile',
        //props: true,
        component: Profile,
        beforeEnter: AuthGuard
    },
    { 
        path: '*',
        name: 'Not Found',
        component: () =>
            import ('@/views/NotFound.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}
})


