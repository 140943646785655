<template>
	<v-alert error dismissable v-model="alert" @input="onClose" :value="true">
		{{ text }}
	</v-alert>
</template>

<script>
	export default {
		props: ['text'],
		methods: {
			onClose () {
				this.$emit('dismissed')
			}
		}
	}
</script>
