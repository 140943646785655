<template>
  <v-container>
    <!--
    <section
    width="100%"
    height="400px">
        <v-parallax :src="imageLink.sub_main" height="600">
          <v-layout column align-center justify-center class="white--text">
            <h1 class="white--text mb-2 display-1 text-xs-center" style="font-weight: 900; text-shadow: 3px 2px #000000">The Crowdsourced Fintech List</h1>
            <div class="white--text subheading mb-3 text-xs-center" style="font-weight: 900; text-shadow: 2px 2px #000000">Review and choose the right Fintechs for you. But also suggest, edit and rectify their info</div>
            <v-btn class="blue  mt-5" dark large href="/login">
              Start Suggesting
            </v-btn>
          </v-layout>
        </v-parallax>
      </section>
    -->
<!--     <v-row>
      <div class="page-header page-header-small">
        <parallax
          class="page-header-image"
          style="background-image: url('assets/FL_banner.png')"
        >
        </parallax>
        <div class="content-center">
          <div class="container">
            <h1 class="title">This is our great company.</h1>
            
          </div>
        </div>
      </div>
    </v-row>-->
    <v-row v-if="categoryid">
      <v-col xs12 sm6 offset-sm3>
        <h1 >Fintechs for {{categoryid}} </h1>
      </v-col>
    </v-row>
    <v-row v-if="countryid">
      <v-col xs12 sm6 offset-sm3>
        <h1 >Fintechs working in {{countryid}} </h1>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col
      v-for="n in 20"
      :key="n"
      cols="12"
      lg="3" md="4" sm="6">
        <v-skeleton-loader
          
          aspect-ratio="1"
          max-height="250"
          max-width="420"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <!--
    <v-row v-if="loading"> 
      <v-container>
      <v-col xs12 class="text-xs-center">
        <v-card
          aspect-ratio="1"
          height="300"
          >
            <v-img
              :src="imageLink.banner">
            </v-img>
          </v-card>
      </v-col>
    </v-container>
    </v-row>
  -->
    <v-row> 
      <v-container>
      <v-col xs12 class="text-xs-center">
        <v-progress-circular
          indeterminate
          class="primary--text"
          :width="7"
          :size="70"
          v-if="loading"></v-progress-circular>
      </v-col>

      
    </v-container>
    </v-row>
    <v-container>
    <v-row > 
      <v-col v-for="fintech in fintechs"
          :key="`${fintech.id}`"  lg="3" md="4" sm="6">
          <v-hover v-slot:default="{ hover }">
          <v-card
            
            aspect-ratio="1"
            height="250"
            width="420"
            :to="'/'+fintech.name"
          >
            
            <v-img
              :src="`${fintech.image}`"
              lazy-src='../assets/lazy_fintech.png'
              aspect-ratio="0.7"
              class="grey darken-4"
              placeholder="Hi"
              height="250"
              width="420"
            >
            <v-expand-transition>
                <div v-if="hover" class="transition-fast-in-fast-out black darken-2 v-card--reveal display-3 white--text" style="height: 100%;"> 

                <!--
          {{ todo.text }}

        -->
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                top
              >
                <v-toolbar-title >
                  <v-btn  width="150" color="WHITE" :to="'/'+fintech.name">
                  {{fintech.name}}
                </v-btn>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                
                <v-btn
                  color="white"
                  icon
                >
                {{fintech.votes}}
                <v-icon color="white">mdi-heart</v-icon>
                
                </v-btn>
                
                
            
              
                
              </v-app-bar>
              
              <v-spacer></v-spacer>
              <div class="text-center">
                    <v-btn
                    v-for="category in fintech.categories"
                    :key="`${category}`"
                    outlined
                    rounded
                    color="white"
                    small
                    >
                    {{category}}
                    </v-btn>
              </div>

            
              <v-card-text justified>
                <div class="body-2" >
                  {{fintech.short_des}}
                </div>
              </v-card-text>
                    
             

            </div>
          </v-expand-transition>

            

            
          </v-img>
            

          </v-card>
        </v-hover>
      </v-col>
     </v-row>
     </v-container>

    <v-snackbar v-model="snackbar" :left="$vuetify.breakpoint.lgAndUp">
      You have selected {{ selectedFintech.name }}
      <v-btn color="pink" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>


<script>

//import fintechsData from '../data/fintechs.json'


export default {
  name: 'DashboardPage',
  components: {
    
  },
  props: ['categoryid','countryid'],
  
  computed: {
    fintechs () {
      return this.$store.getters.loadedFintechs
    },
    loading () {
      return this.$store.getters.loading
    }
    // paidFintechs () {
    //   return this.$store.getters.paidFintechs
    // }
  },
  data() {
    return {

      // fintechs: fintechsData,
      imageLink: {
        banner:
          "https://firebasestorage.googleapis.com/v0/b/fintechlist-38539.appspot.com/o/FL_Banner.png?alt=media&token=495f4bdb-9abd-45b9-ad7e-86db756ad985",
        sub_main:
          "https://firebasestorage.googleapis.com/v0/b/fintechlist-38539.appspot.com/o/HeroShot.png?alt=media&token=7a783f2d-9b4a-416a-8165-993eb01e0bbf",
        logo:
          "",
        social_cover:
          ""
      },
      loadNewContent: false,
      selectedFintech: {
        id: '',
        name: ''
      },
      showMenu: false,
      model: null,
      snackbar: false,
      fintechFilterKey: 'all',
      overlay: false,
      fintechs_data_actual: [],
      active_id: 0,
      options: [
        { value: null, text: "Sort By" },
        { value: "a", text: "Ratings" },
        { value: "b", text: "Likes" }
      ],
      search: { filter: null, text: "" },
      likes: { count: 0, hit: 0 }
    }
  },
  watch: {
      overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 2000)
      },
    },
  methods: {
    mounted() {
      filterObj.category = categoryid
      filterObj.country = countryid
      filterFintechs
    },
    filterFintechs() {
                this.$store.dispatch('filterFintechs', 
                this.filterObj)
    },
    setFintech(event) {
      this.snackbar = true
      this.selectedEmployee.name = event.name
      this.selectedEmployee.title = event.title
    },
    customFilter(items, search, filter) {

        search = search.toString().toLowerCase()
        return items.filter(row => filter(row["categories"], search));

    },
    showMoreContent(entries) {
      this.loadNewContent = entries[0].isIntersecting
    },
    sort() {
      //console.log(this.search.filter);
      this.search.filter == "b"
        ? this.fintechs.sort(function(a, b) {
            return b.likes - a.likes;
          })
        : this.fintechs.sort(function(a, b) {
            return b.ratings - a.ratings;
          });
    },
    search_text() {
      //console.log(this.search.text);
      var inside = this;
      this.fintechs = this.fintechs_data_actual.filter(function(fintech) {
        if (
          fintech.name
            .toLowerCase()
            .indexOf(inside.search.text.toLowerCase()) != "-1"
        ) {
          return fintech;
        }
      });
    },
    check_active(id) {
      var flag = false;
      this.fintechs_data_actual.map(function(fintech) {
        if (fintech.id == id) {
          flag = fintech.active_like;
        }
      });
      return flag;
    },
    make_active(id) {
      this.likes.hit++;
      this.fintechs_data_actual = this.fintechs_data_actual.map(function(fintech) {
        if (fintech.id == id) {
          fintech.active_like = !fintech.active_like;
          fintech.active_like ? fintech.votes++ : fintech.votes--;
        }
return fintech;
      });
      var inside = this;
inside.likes.count = 0;
      this.fintechs_data_actual.map(function(fintech) {
        inside.likes.count += fintech.votes;
      });
    }
  },
  }

</script>

<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
}

</style>