import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index.js'
import SimpleAnalytics from "simple-analytics-vue";
import VueMeta from 'vue-meta';
import { store } from './store'
import firebase from 'firebase'
import AlertCmp from './components/Alert.vue'
import EditFintechDetailsDialog from './components/EditFintechDetailsDialog.vue'
import VoteDialog from './components/VoteDialog.vue'
 
Vue.use(SimpleAnalytics, { domain: "fintechlist.io" });
Vue.config.productionTip = false
Vue.use(VueMeta);
Vue.use(firebase)
Vue.component('app-alert', AlertCmp)
Vue.component('app-edit-fintech-details-dialog', EditFintechDetailsDialog)
Vue.component('vote-dialog', VoteDialog)

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App),
	created () {
		let app =firebase.initializeApp({
			apiKey: "AIzaSyB-bLFG9Sk0MLWyFHb7qumLA6RHi9cc65c",
		    authDomain: "fintechlist-38539.firebaseapp.com",
		    databaseURL: "https://fintechlist-38539-default-rtdb.europe-west1.firebasedatabase.app",
		    projectId: "fintechlist-38539",
		    storageBucket: "fintechlist-38539.appspot.com"
		})
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.$store.dispatch('autoSignIn', user)
				this.$store.dispatch('fetchUserData')

			}
		})
		this.$store.dispatch('loadFintechs')
	}
}).$mount('#app')

// Route case-sensitivity hotfix
if (router.mode === 'history') {
  router.history.getCurrentLocation = function() {
    let path = window.location.pathname
    let base = router.history.base

    // Removes base from path (case-insensitive)
    if (base && path.toLowerCase().indexOf(base.toLowerCase()) === 0) {
      path = path.slice(base.length)
    }

    return (path || '/') + window.location.search + window.location.hash
  }
}

